import React from "react";
import { RLPaper } from "../../interfaces/Paper";
import { DataGridPro, GridEventListener } from "@mui/x-data-grid-pro";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import PaperDetailsTable from "../PaperDetailsTable/PaperDetailsTable";

const IconWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

function RLPaperDataGrid({
  papers,
  categories,
  setSelectedDocument,
}: {
  papers: RLPaper[];
  categories: string[];
  setSelectedDocument: (document: RLPaper) => void;
}) {
  console.log(papers);
  const columns: any = [
    {
      field: "error",
      headerName: "",
      maxWidth: 50,
      renderCell: (params: any) => {
        if (params.row.error) {
          return (
            <IconWrapper>
              <ErrorIcon htmlColor="red" />
              <span style={{ fontSize: 10 }}>Error</span>
            </IconWrapper>
          );
        }
        return null;
      },
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 200,
      valueGetter: (params: any) =>
        params.row.mendeley?.title || params.row.title || params.row.name,
    },
    {
      field: "authors",
      headerName: "Authors",
      minWidth: 100,
      valueGetter: (params: any) => {
        const authors = params.row.mendeley?.authors || params.row.authors;
        if (Array.isArray(authors)) {
          return authors
            .map((author) =>
              "first_name" in author && "last_name" in author
                ? `${author.first_name} ${author.last_name}`
                : author
            )
            .join(", ");
        }
        return authors;
      },
    },
    {
      field: "url",
      headerName: "URL",
      minWidth: 100,
      renderCell: (params: any) => (
        <a href={params.row.mendeley?.link || params.row.url}>View online</a>
      ),
    },
    {
      field: "year",
      headerName: "Year",
      minWidth: 50,
      valueGetter: (params: any) =>
        params.row.mendeley?.year || params.row.year,
    },
  ];

  for (const category of categories) {
    columns.push({
      field: category,
      headerName: category,
      align: "center",
      minWidth: 100,
      renderCell: (params: any) => {
        // check if the row has categories

        const cat = category.toLocaleLowerCase()

        if (!params.row.categories) {
          return (
            <IconWrapper>
              <ErrorIcon htmlColor="red" />
              <span style={{ fontSize: 10 }}>Error</span>
            </IconWrapper>
          );
        }

        const categories: any = {}

        for (const key in params.row.categories) {
          categories[key.toLocaleLowerCase()] = params.row.categories[key]
        }

        let keys = Object.keys(params.row.categories);
        keys = keys.map((key) => key.toLowerCase())

    
        // check if the category is in the keys
        if (!keys.includes(cat)) {
          return (
            <IconWrapper>
              {/* <ErrorIcon htmlColor="red" />*/}
              <ClearIcon htmlColor="red" /> 
              <span style={{ fontSize: 10 }}>-</span>
            </IconWrapper>
          );
        }

        if (categories[cat] >= 0.74) {
          return (
            <IconWrapper>
              <DoneIcon htmlColor="green" />
              <span style={{ fontSize: 10 }}>
                {+categories[cat] * 100}%
              </span>
            </IconWrapper>
          );
        } else if (categories[cat] >= 0.51) {
          return (
            <IconWrapper>
              <DoneIcon htmlColor="orange" />
              <span style={{ fontSize: 10 }}>
                {+categories[cat] * 100}%
              </span>
            </IconWrapper>
          );
        } else {
          return (
            <IconWrapper>
              <ClearIcon htmlColor="red" />
              <span style={{ fontSize: 10 }}>
                {categories[cat] * 100}%
              </span>
            </IconWrapper>
          );
        }
      },
    });
  }

  const handleEvent: GridEventListener<"cellClick"> = (
    params, // GridCellParams<any>
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    console.log(params);
    console.log(event);

    if (params.field === "title") {
      // Trigger event to open the document details dialog
      console.log("Open document details dialog");
      setSelectedDocument(params.row);
    }
  };

  const getDetailPanelContent = ({ row }: any) => {
    return (
      <Box padding={2} style={{ backgroundColor: "#f5f5f5" }}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Paper Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} sx={{mb: 3}}>
            {row.mendeley && <PaperDetailsTable paperDetails={row.mendeley} />}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ fontWeight: "bold" }}>ResearchLens Overview</div>
            <div style={{ marginRight: 30 }}>
              <p>{row.researchLensSummary}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {row.mendeley?.keywords?.length > 0 && (
              <>
                <div style={{ fontWeight: "bold" }}>Keywords</div>
                <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
                  {row.mendeley?.keywords?.map((keyword: string) => (
                    <Chip
                      label={keyword}
                      style={{ marginRight: 5 }}
                      color="primary"
                    />
                  ))}
                </Stack>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <div style={{ height: 1000, width: "100%" }}>
      <DataGridPro
        columns={columns}
        rows={papers}
        onCellClick={handleEvent}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => "auto"}
      />
    </div>
  );
}

const PaperTable = ({
  papers,
  categories,
  setSelectedDocument,
}: {
  papers: RLPaper[];
  categories: string[];
  setSelectedDocument: (document: RLPaper) => void;
}) => {
  // MUI Datagrid that shows the papers
  const rlPapers = papers.map((paper) => ({
    id: paper._id,
    ...paper,
  }));

  return (
    <RLPaperDataGrid
      papers={rlPapers}
      categories={categories}
      setSelectedDocument={setSelectedDocument}
    />
  );
};

export default PaperTable;
