import React, { ReactNode, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/user.context";

interface ProtectedRouteProps {
  children: ReactNode;
  requiredRole?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requiredRole,
}) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  const { userRoles } = React.useContext(UserContext);

  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
    } else if (!isLoading && isAuthenticated && userRoles.length > 0) {
      setLoading(false);
    }
  }, [isAuthenticated, userRoles, isLoading]);

  React.useEffect(() => {
    if (isLoading || loading) {
      return;
    }

    if (!isAuthenticated) {
      navigate("/");
    }

    if (requiredRole && !userRoles.includes(requiredRole)) {
      navigate("/");
    }
  }, [isAuthenticated, navigate, isLoading, userRoles, requiredRole, loading]);

  if (isLoading || loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <>{children}</> : null;
};


export default ProtectedRoute;