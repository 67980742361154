import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardPage from "./pages/dashboard";
import ProtectedRoute from "./auth/ProtectedRoute";
import HomePage from "./pages/home";
import OrganisationPage from "./pages/organisations";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import ProjectPage from "./pages/project";

const App = () => {
  return (
    <div style={{ height: "100%" }}>
      <Helmet>
        <title>ResearchLens by RODA Technology</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/organisations"
          element={
            <ProtectedRoute requiredRole="researchlens-admin">
              <OrganisationPage />
            </ProtectedRoute>
          }
        />
        {/* Project path with id */}
        <Route
          path="/projects/:id"
          element={
            <ProtectedRoute>
              <ProjectPage />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </div>
  );
};

export default App;
