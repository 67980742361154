// src/components/PageMenu.tsx
import React from "react";
import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Box, Button } from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowBack";

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  margin-right: 12px;

  &.active {
    .MuiButton-root {
      color: #1976d2;
      background-color: rgba(25, 118, 210, 0.1);
      border-radius: 5px;
    }
  }
`;

const BackButton: React.FC<any> = ({ to, name }: { to: string, name: string}) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      style={{ marginBottom: 20 }}
    >
      <StyledNavLink to={to}>
        <Button startIcon={<BackIcon />}>Back to {name}</Button>
      </StyledNavLink>
    </Box>
  );
};

export default BackButton
