import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { RLPaper } from "../../interfaces/Paper";

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const PaperDetailsTable = ({
  paperDetails,
}: {
  paperDetails: RLPaper["mendeley"];
}) => {
  if (!paperDetails) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TableContainer component={Paper} style={{ backgroundColor: "white" }}>
          <Table>
            <TableBody>
              {Object.entries(paperDetails).map(([key, value]) => {
                if (
                  key === "identifiers" ||
                  key === "keywords" ||
                  key === "id" ||
                  key === "has_pdf"
                ) {
                  return null; // Skip identifiers here
                }

                // if the value is an array of objects (authors), join their names into a single string
                if (
                  Array.isArray(value) &&
                  value.length > 0 &&
                  typeof value[0] === "object"
                ) {
                  value = value
                    .map(
                      (author: any) =>
                        `${author.first_name} ${author.last_name}`
                    )
                    .join(", ");
                }

                return (
                  <TableRow key={key}>
                    <TableCell style={{ fontWeight: "bold", padding: "6px" }}>
                      {toTitleCase(key)}
                    </TableCell>
                    <TableCell style={{ padding: "6px" }}>
                      {key === "link" ? (
                        <a
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Paper
                        </a>
                      ) : (
                        value
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        <TableContainer component={Paper} style={{ backgroundColor: "white" }}>
          <Table>
            <TableBody>
              {Object.entries(paperDetails.identifiers).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell style={{ fontWeight: "bold", padding: "6px" }}>
                    {key.toUpperCase()}
                  </TableCell>
                  <TableCell style={{ padding: "6px" }}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PaperDetailsTable;
