import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { UserService } from "../services/user";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import NavBar from "../components/NavBar";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import BackButton from "../components/atoms/BackButton/BackButton";
import { useParams } from "react-router-dom";
import { ProjectService } from "../services/projects";
import PapersTable from "../components/PapersTable/PapersTable";
import { ProjectStatusChip } from "../components/atoms/ProjectStatusChip/ProjectStatusChip";

const PageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const ProjectPage = () => {
  const [token, setToken] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [project, setProject] = React.useState<any>();
  const [selectedDocument, setSelectedDocument] = React.useState<any>();

  const { id } = useParams<{ id: string }>();

  const [showLoadingBackdrop, setShowLoadingBackdrop] =
    React.useState<boolean>(false);

  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "https://api.researchlens.ai",
          scope:
            "read:current_user update:current_user_metadata manage:organisations",
        },
      });

      setToken(token);
    } catch (error: any) {
      // Check if there is a consent required error
      if (error.error === "consent_required") {
        // If so, get the user to consent
        await getAccessTokenWithPopup({
          authorizationParams: {
            audience: "https://api.researchlens.ai",
            scope:
              "read:current_user update:current_user_metadata manage:organisations",
          },
        });
      }
    }
  };

  useEffect(() => {
    getAccessToken();
  });

  useEffect(() => {
    setShowLoadingBackdrop(true);
    const getUser = async () => {
      if (token) {
        const userService = new UserService(token);
        const { data } = await userService.getProfile();
        setName(data.name);
        setEmail(data.email);
      }
    };

    const getProject = async () => {
      if (token && id) {
        const projectService = new ProjectService(token);
        const { data } = await projectService.getProject(id);
        setProject(data);
      }
    };

    Promise.all([getUser(), getProject()]).then(() => {
      setShowLoadingBackdrop(false);
    });
  }, [token, id]);

  // Set up a refresh if the project is IN PROGRESS
  useEffect(() => {
    const getProject = async () => {
      if (token && id) {
        const projectService = new ProjectService(token);
        const { data } = await projectService.getProject(id);
        setProject(data);
      }
    };

    const interval = setInterval(() => {
      if (project?.status === "IN_PROGRESS" || project?.status === "CREATED") {
        getProject();
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (selectedDocument) {
      console.log(selectedDocument);
    }
  }, [selectedDocument]);

  return (
    <div>
      <NavBar name={name} email={email} />
      <PageWrapper>
        <BackButton to="/dashboard" name="dashboard" />
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          {project?.name}
        </Typography>

        {/* Show status chip */}
        {project && (
          <Box sx={{ marginBottom: 3 }}>
            <ProjectStatusChip status={project.status} />
          </Box>
        )}

        {/* Show papers in prokect */}
        {project && (
          <PapersTable
            papers={project.documents}
            categories={project.categories}
            setSelectedDocument={setSelectedDocument}
          />
        )}
      </PageWrapper>

      {/* Loading Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: 100000 }}
        open={showLoadingBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>
  );
};

export default ProjectPage;
