import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import CreateProjectForm from "./CreateProjectForm";

const CreateProjectDialog = ({ open, onClose, onSubmit }: any) => {
  const handleSubmit = (formData: any) => {
    onSubmit(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create Project</DialogTitle>
      <DialogContent>
        <CreateProjectForm onSubmit={handleSubmit} close={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateProjectDialog;
