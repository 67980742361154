import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useEffect, useState } from "react";

const defaultContext = {
  userRoles: [],
};

export const UserContext = createContext<{
  userRoles: string[];
}>(defaultContext);

const UserContextProvider = ({ children }: any) => {
  const [userRoles, setUserRoles] = useState([]);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const getClaims = async () => {
      try {
        const claims: any = await getIdTokenClaims();
        const roles = claims["https://researchlens.ai/roles"];
        setUserRoles(roles);
      } catch (e) {
        return;
      }
    };

    getClaims();

    if (!isAuthenticated) {
      setUserRoles([]);
      return;
    }
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <UserContext.Provider value={{ userRoles }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
