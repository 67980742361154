import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/system";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { keyframes } from "@emotion/react";
// import Logo from "./path/to/your/logo.svg"; // Replace with your logo file path

const gradientAnimation1 = keyframes`
  0%, 100% {
    background-position: 50% 50%;
  }
  15% {
    background-position: 75% 25%;
  }
  30% {
    background-position: 25% 75%;
  }
  45% {
    background-position: 75% 25%;
  }
  60% {
    background-position: 25% 75%;
  }
  75% {
    background-position: 75% 25%;
  }
  90% {
    background-position: 25% 75%;
  }
`;

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  background: "linear-gradient(135deg, #bf0d5c, #1c3144);",
  animation: `${gradientAnimation1} 10s ease infinite`,
});

const StyledCard = styled(Card)({
  minWidth: 275,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: 25,
});

const ButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  padding: "20px",
});

const Homepage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      // Redirect to dashboard
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  return (
    <StyledDiv>
      <StyledCard>
        <CardContent>
          {/* <StyledLogo src={Logo} alt="Logo" /> */}
          <Typography variant="h3">ResearchLens</Typography>
          <Typography variant="subtitle2">
            A research management tool
          </Typography>

          <ButtonWrapper>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleLogin}
            >
              Log In
            </Button>
          </ButtonWrapper>
        </CardContent>
      </StyledCard>
    </StyledDiv>
  );
};

export default Homepage;
