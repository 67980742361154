import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Paper } from "@mui/material";
import { ProjectStatusChip } from "../atoms/ProjectStatusChip/ProjectStatusChip";
import { useAuth0 } from "@auth0/auth0-react";
import { ProjectService } from "../../services/projects";
import { NavLink } from "react-router-dom";

const RefreshIntervals = {
  CREATED: 1000,
  IN_PROGRESS: 10000,
};

const StatusCell = ({ status, projectId }: any) => {
  const [currentStatus, setCurrentStatus] = useState(status);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let timer: any;
    if (currentStatus === "CREATED" || currentStatus === "IN_PROGRESS") {
      timer = setInterval(() => {
        // Poll the server and update the status if necessary
        // Replace the following example function with your actual API call
        async function fetchStatus() {
          const token = await getAccessTokenSilently();
          const projectService = new ProjectService(token);
          const data = await projectService.getProject(projectId);

          // Make your API call using the token
          // If the status has changed, update the currentStatus state
          setCurrentStatus(data.data.status);
        }

        fetchStatus();
      }, RefreshIntervals[currentStatus as keyof typeof RefreshIntervals] as number); // Poll every 5 seconds
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [currentStatus, getAccessTokenSilently, projectId]);

  return <ProjectStatusChip status={currentStatus} />;
};

const ProjectsTable = ({ projects }: any) => {
  const columns = [
    { field: "name", headerName: "Project Name" },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params: any) => {
        return (
          <StatusCell status={params.row.status} projectId={params.row.id} />
        );
      },
    },
    { field: "user", headerName: "Created by", width: 300 },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params: any) => {
        return (
          <NavLink to={`/projects/${params.row.id}`}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
            >
              View Project
            </Button>
          </NavLink>
        );
      },
    },
  ];

  const rows = projects.map((project: any) => ({
    id: project._id,
    name: project.name,
    status: project.status,
    user: project.user.name,
  }));

  return (
    <Paper elevation={3} style={{ height: 400, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} />
    </Paper>
  );
};

export default ProjectsTable;
