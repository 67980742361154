import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material";

const UserNameBanner = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface NavBarProps {
  name: string;
  email: string;
}

const NavBar = ({ name, email }: NavBarProps) => {
  const { isAuthenticated, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <AppBar position="static">
      <Toolbar color="primary">
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          ResearchLens
        </Typography>

        {isAuthenticated && (
          <UserNameBanner>
            <Typography variant="subtitle1" style={{ marginLeft: 10 }}>
              {name}
            </Typography>

            <IconButton
              edge="end"
              color="inherit"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <AccountCircle />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
          </UserNameBanner>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
