import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { styled } from "@mui/material";


const RLTextField = styled(TextField)`
  margin-bottom: 20px;
`;


const AddOrganizationForm = ({ onSubmit }: { onSubmit: any}) => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [maxDocs, setMaxDocs] = useState("");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit({ name, url, maxDocs });
    setName("");
    setUrl("");
    setMaxDocs("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <RLTextField
        label="Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        fullWidth
        required
      />
      <RLTextField
        label="URL"
        value={url}
        onChange={(event) => setUrl(event.target.value)}
        fullWidth
        required
      />
      <RLTextField
        label="Maximum monthly documents"
        value={maxDocs}
        onChange={(event) => setMaxDocs(event.target.value)}
        fullWidth
        required
        type="number"
      />
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        Submit
      </Button>
    </form>
  );
};

export default AddOrganizationForm;
