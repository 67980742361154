// src/components/PageMenu.tsx
import React from "react";
import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Box, Button } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import OrganizationIcon from "@mui/icons-material/Business";

import { UserContext } from "../../contexts/user.context";

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  margin-right: 12px;

  &.active {
    .MuiButton-root {
      color: #1976d2;
      background-color: rgba(25, 118, 210, 0.1);
      border-radius: 5px;
    }
  }
`;

const PageMenu: React.FC = () => {
  const { userRoles } = React.useContext(UserContext);

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      style={{ marginBottom: 20 }}
    >
      <StyledNavLink to="/dashboard">
        <Button startIcon={<DashboardIcon />}>Dashboard</Button>
      </StyledNavLink>

      {/* Admin Routes */}

      {userRoles.includes("researchlens-admin") && (
        <>
          <StyledNavLink to="/organisations">
            <Button startIcon={<OrganizationIcon />}>Organisations</Button>
          </StyledNavLink>
          {/* <StyledNavLink to="/users">
            <Button startIcon={<UserIcon />}>Users</Button>
          </StyledNavLink> */}
        </>
      )}
    </Box>
  );
};

export default PageMenu;
