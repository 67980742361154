import { createTheme } from "@mui/material";

export const theme = createTheme({

  palette: {
    primary: {
      main: "#1c3144",
    },
  },

  typography: {
    fontFamily: [
      "Anuphan",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  // Set h4 to have a larger weight
  components: {
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontWeight: 600,
        },
        h5: {
          fontWeight: 600,
        }
      },
    },
  },

});
