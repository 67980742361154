import React from "react";
import { Box, Chip, CircularProgress } from "@mui/material";

export enum ProjectStatus {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export const ProjectStatusChip = ({
  status,
}: {
  status: ProjectStatus;
}): JSX.Element => {
  switch (status) {
    case ProjectStatus.CREATED:
      return <Chip label="Created" color="primary" />;
    case ProjectStatus.IN_PROGRESS:
      return (
        <Chip
          label={
            <Box display="flex" alignItems="center">
              <CircularProgress size={16} thickness={5} color="primary"/>
              <Box marginLeft={1}>In Progress</Box>
            </Box>
          }
          color="warning"
        />
      );
    case ProjectStatus.COMPLETED:
      return <Chip label="Completed" color="success" />;
    case ProjectStatus.FAILED:
      return <Chip label="Failed" color="error" />;
    default:
      <div></div>;

      return <div></div>;
  }
};
