import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { UserService } from "../services/user";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import NavBar from "../components/NavBar";
import { OrganisationService } from "../services/organisation";
import { Button } from "@mui/material";
import CreateOrgDialog from "../components/CreateOrganisationDialog/CreateaOrganisationDialog";
import OrganisationsTable from "../components/OrganisationsTable/organisationsTable";
import PageMenu from "../components/PageMenu/PageMenu";

interface CreateOrgFormProperties {
  name: string;
  url: string;
  maxDocs: string;
}

const PageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

// Styled component that will allow the title on the left, and a button to the right
const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const OrganisationPage = () => {
  const [token, setToken] = React.useState<string>("");

  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");

  const [createOrgDialogOpen, setCreateOrgDialogOpen] = React.useState(false);

  const [organisations, setOrganisations] = React.useState<any>([]);

  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "https://api.researchlens.ai",
          scope:
            "read:current_user update:current_user_metadata manage:organisations",
        },
      });

      setToken(token);
    } catch (error: any) {
      // Check if there is a consent required error
      if (error.error === "consent_required") {
        // If so, get the user to consent
        await getAccessTokenWithPopup({
          authorizationParams: {
            audience: "https://api.researchlens.ai",
            scope:
              "read:current_user update:current_user_metadata manage:organisations",
          },
        });
      }
    }
  };

  useEffect(() => {
    getAccessToken();
  });

  useEffect(() => {
    const getUser = async () => {
      if (token) {
        const userService = new UserService(token);
        const { data } = await userService.getProfile();

        setName(data.name);
        setEmail(data.email);
      }
    };

    const getOrganisations = async () => {
      if (token) {
        const organisationService = new OrganisationService(token);
        const { data } = await organisationService.getAll();
        setOrganisations(data);
      }
    };

    getUser();
    getOrganisations();
  }, [token]);

  const handleDialogToggle = () => {
    setCreateOrgDialogOpen(!createOrgDialogOpen);
  };

  const handleCreateOrganisationSubmit = async (
    formProps: CreateOrgFormProperties
  ) => {
    console.log(formProps);
    const organisationService = new OrganisationService(token);
    const { data } = await organisationService.createOrganisation(formProps);
    console.log(data);
  };

  const onDialogClose = () => {
    setCreateOrgDialogOpen(false);
  };

  return (
    <div>
      <NavBar name={name} email={email} />
      <PageWrapper>
        <PageMenu />
        <TitleWrapper>
          <Typography variant="h4">Organisations</Typography>
          <Button onClick={handleDialogToggle} color="primary">
            {" "}
            Create Org
          </Button>
        </TitleWrapper>

        {/* Table of organisations here */}
        <OrganisationsTable organisations={organisations} />
      </PageWrapper>

      <CreateOrgDialog
        open={createOrgDialogOpen}
        onSubmit={handleCreateOrganisationSubmit}
        onClose={onDialogClose}
      />
    </div>
  );
};

export default OrganisationPage;
