import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";
import "./styles/global.scss";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./styles/theme";
import UserContextProvider from "./contexts/user.context";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Auth0ProviderWithHistory>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </Auth0ProviderWithHistory>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
