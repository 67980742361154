import { RLApi } from "./api";
export class UserService {
  private api: RLApi;

  constructor(token: string) {
    this.api = new RLApi(token);
  }

  public async getProfile() {
    return (await this.api.get("/users/me")).data;
  }
}
