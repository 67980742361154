import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import AddOrganizationForm from "./CreateOrganisationForm";

const AddOrganizationDialog = ({ open, onClose, onSubmit }: any) => {
  const handleSubmit = ({ name, url, maxDocs }: any) => {
    onSubmit({ name, url, maxDocs });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Organization</DialogTitle>
      <DialogContent>
        <AddOrganizationForm onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default AddOrganizationDialog;
