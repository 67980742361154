import { RLApi } from "./api";

interface CreateOrganisationDto {
  name: string;
  url: string;
  maxDocs: string;
}

export class OrganisationService {
  private api: RLApi;

  constructor(token: string) {
    this.api = new RLApi(token);
  }

  public async createOrganisation(organisation: CreateOrganisationDto) {
    return (await this.api.post("/organisation", organisation)).data;
  }

  public async getAll() {
    return (await this.api.get("/organisation")).data;
  }
}
