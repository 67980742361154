import { RLApi } from "./api";

export class ProjectService {
  private api: RLApi;

  constructor(token: string) {
    this.api = new RLApi(token);
  }

  public async createProject(project: FormData) {
    return new Promise((resolve, reject) => {
      this.api
        .post("/projects", project, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  public async getProject(id: string) {
    return (await this.api.get(`/projects/${id}`)).data;
  }

  public async getAll() {
    return (await this.api.get("/projects")).data;
  }
}
