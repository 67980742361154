import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import { format, addMonths } from "date-fns";

interface UserOrgInfoCardProps {
  organisation: any;
}

const UserOrgInfoCard: React.FC<UserOrgInfoCardProps> = ({
  organisation,
}: any) => {
  const { name, url, quota } = organisation;

  const resetDate = addMonths(new Date(quota.resetDate), 1);

  return (
    <Card sx={{ marginTop: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h6" color="text.primary">
          {name}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {url}
        </Typography>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">
                Max Monthly Documents:
              </Typography>
              <Typography variant="h6">{quota.maxMonthlyDocuments}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">Used Documents:</Typography>
              <Typography variant="h6">{quota.usedDocuments}</Typography>
            </Grid>
            {/* Reset Date */}
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">Reset Date:</Typography>
              <Typography variant="h6">
                {format(resetDate, "dd/MM/yyy HH:mm")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserOrgInfoCard;
