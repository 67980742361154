import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';

const OrganisationsTable = ({ organisations }: any) => {
  const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'url', headerName: 'URL', width: 250 },
    { field: 'maxMonthlyDocuments', headerName: 'Max Documents', width: 200 },
    { field: 'usedMonthlyDocuments', headerName: 'Used Documents', width: 200 },
  ];

  const rows = organisations.map((org: any) => ({
    id: org._id,
    name: org.name,
    url: org.url,
    maxMonthlyDocuments: org.quota.maxMonthlyDocuments,
    usedMonthlyDocuments: org.quota.usedDocuments,
  }));

  return (
    <Paper elevation={3} style={{ height: 400, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
      />
    </Paper>
  );
};

export default OrganisationsTable;
