import axios, { AxiosInstance } from "axios";

export class RLApi {
  private apiInstance: AxiosInstance;

  constructor(authToken: string) {
    this.apiInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  }

  public async get(path: string) {
    return await this.apiInstance.get(path);
  }

  public async post(path: string, data: any, headers?: any) {
    return await this.apiInstance.post(path, data, headers);
  }

}
