// CreateProjectForm.js
import React, { useState } from "react";
import {
  Button,
  Container,
  TextField,
  Box,
  Divider,
  Chip,
  Stack,
  IconButton,
  InputAdornment,
  FormHelperText,
  FormControl,
  FormLabel,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CreateProjectForm = ({ onSubmit, close }: any) => {
  const [name, setName] = useState("");
  const [organisationId, setOrganisationId] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [zipFile, setZipFile] = useState<any>(null);
  const [category, setCategory] = useState("");

  const handleInputChange = (event: any) => {
    setCategory(event.target.value);
  };

  const handleFileChange = (event: any) => {
    setZipFile(event.target.files[0]);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("organisationId", organisationId);
    formData.append("categories", categories.join(","));
    formData.append("file", zipFile);

    onSubmit(formData);

    setName("");
    setOrganisationId("");
    setCategories([]);
    setZipFile(null);
  };

  const handleAddCategory = (event: any) => {
    if (event.key === "Enter" && category.trim() !== "") {
      event.preventDefault();
      setCategories([...categories, category.trim()]);
      setCategory("");
    }
  };

  const handleDeleteCategory = (categoryToDelete: string) => {
    setCategories(
      categories.filter((category) => category !== categoryToDelete)
    );
  };

  const addCategory = () => {
    if (category.trim() !== "") {
      setCategories([...categories, category.trim()]);
      setCategory("");
    }
  };

  const handleCancel = () => {
    setName("");
    setOrganisationId("");
    setCategories([]);
    setZipFile(null);
  };

  return (
    <Container sx={{ margin: 0, padding: 0 }} style={{ padding: 0 }}>
      <Box sx={{ margin: 0, padding: 0 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            id="name"
            label="Project Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            label="Category"
            value={category}
            onChange={handleInputChange}
            onKeyDown={handleAddCategory}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={addCategory}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>
            Add categories by typing and pressing Enter.
          </FormHelperText>
          <Stack
            direction="row"
            spacing={0}
            sx={{ paddingBottom: 2, paddingTop: 2 }}
            flexWrap={"wrap"}
            gap={1}
          >
            {categories.map((category, index) => (
              <Chip
                key={index}
                label={category}
                onDelete={() => handleDeleteCategory(category)}
              />
            ))}
          </Stack>

          <Divider />
          <Box sx={{ marginTop: 2 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="file-upload-input">
                Upload ZIP file with papers
              </FormLabel>
              <TextField
                id="file-upload-input"
                type="file"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: "zip", // Adjust the accepted file types as needed
                  onChange: handleFileChange,
                }}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </FormControl>
          </Box>

          <Box
            sx={{ marginTop: 2 }}
            display="flex"
            justifyContent={"space-between"}
          >
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                name.trim() === "" ||
                categories.length === 0 ||
                zipFile === null
              }
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default CreateProjectForm;
