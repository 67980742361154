import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { UserService } from "../services/user";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import NavBar from "../components/NavBar";
import PageMenu from "../components/PageMenu/PageMenu";
import UserOrgInfoCard from "../components/UserOrgInfoCard/UserOrgInfoCard";
import { TitleWrapper } from "../components/atoms/TitleBanner/TitleBanner";
import { Backdrop, Button, CircularProgress, Divider } from "@mui/material";
import CreateProjectDialog from "../components/CreateProjectDialog/CreateProjectDialog";
import { ProjectService } from "../services/projects";
import ProjectsTable from "../components/ProjectTable/ProjectTable";
import { toast } from "react-toastify";

const PageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const Dashboard = () => {
  const [token, setToken] = React.useState<string>("");
  const [projects, setProjects] = React.useState<any>([]);

  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [organisation, setOrganisation] = React.useState<any>(null);

  const [createProjectDialogOpen, setCreateProjectDialogOpen] =
    React.useState<boolean>(false);

  const [showLoadingBackdrop, setShowLoadingBackdrop] =
    React.useState<boolean>(false);

  const handleCreateProjectDialogClose = () => {
    setCreateProjectDialogOpen(false);
  };

  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "https://api.researchlens.ai",
          scope:
            "read:current_user update:current_user_metadata manage:organisations",
        },
      });

      setToken(token);
    } catch (error: any) {
      // Check if there is a consent required error
      if (error.error === "consent_required") {
        // If so, get the user to consent
        await getAccessTokenWithPopup({
          authorizationParams: {
            audience: "https://api.researchlens.ai",
            scope:
              "read:current_user update:current_user_metadata manage:organisations",
          },
        });
      }
    }
  };

  useEffect(() => {
    getAccessToken();
  });

  useEffect(() => {
    const getUser = async () => {
      if (token) {
        const userService = new UserService(token);
        const { data } = await userService.getProfile();
        setName(data.name);
        setEmail(data.email);
        setOrganisation(data.organisation);
      }
    };

    getUser();
  }, [token]);

  useEffect(() => {
    const projectService = new ProjectService(token);
    const getProjects = async () => {
      const { data } = await projectService.getAll();
      setProjects(data);
    };

    getProjects();
  }, [token]);

  const handleCreateProject = async (formData: any) => {
    setShowLoadingBackdrop(true);
    const projectService = new ProjectService(token);

    try {
      await projectService.createProject(formData);
      const { data } = await projectService.getAll();
      setProjects(data);
      setCreateProjectDialogOpen(false);
      setShowLoadingBackdrop(false);
    } catch (error: any) {
      setShowLoadingBackdrop(false);
      toast("Something went wrong. Please try again later.");
    }
  };

  const handleOpenNewProjectDialog = () => {
    setCreateProjectDialogOpen(true);
  };

  return (
    <div>
      <NavBar name={name} email={email} />
      <PageWrapper>
        <PageMenu />
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          Dashboard
        </Typography>

        {/* Show Org Info card here */}
        <Typography variant="h5">Your Organisation Details</Typography>
        {organisation && <UserOrgInfoCard organisation={organisation} />}

        <Divider />

        {/* Show Project cards here */}
        <TitleWrapper style={{ marginTop: 20 }}>
          <Typography variant="h5">Your Projects</Typography>

          {/* Create new project button */}
          <Button variant="contained" onClick={handleOpenNewProjectDialog}>
            Create New Project
          </Button>
        </TitleWrapper>

        <ProjectsTable projects={projects} />

        <CreateProjectDialog
          open={createProjectDialogOpen}
          onSubmit={handleCreateProject}
          onClose={handleCreateProjectDialogClose}
        />
      </PageWrapper>

      {/* Loading Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: 100000 }}
        open={showLoadingBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Dashboard;
